import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PurpleTranslationPipe } from 'purple-lib';
import { Subscription } from 'rxjs';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PolicyItem } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
function switchCompanyRedirect() {
  return environment.COMPANY_ABB == 'ke' || environment.COMPANY_ABB == 'swpr';
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  constructor(public tsvc: AppTranslationService, private breakpointObserver: BreakpointObserver, public policySvc: PolicyAndUserAttributesService,
    private tranPipe: PurpleTranslationPipe, private modal: NzModalService) { }

  currentYear: string = new Date().getFullYear().toString();
  policyModeIubenda: boolean = false;
  swVersion: string = "1.0.0";
  cookieLink: string = "#";
  privacyLink: string = "#";
  isMobile: boolean = false;
  subs: Subscription = new Subscription();

  policies: PolicyItem[] = [];
  isKering: boolean = switchCompanyRedirect();
  basePath: string = localStorage.getItem("BasePath")!;
  defLan: string = localStorage.getItem("DefaultLanguage")!;
  countryFlag: string = "";
  showCountrySelect: boolean = environment.SHOW_COUNTRY_SELECT;

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.countryFlag = environment.COUNTRY_SELECT.find(f=> f.COUNTRY_DEFAULT_LANGUAGE.strEq(this.defLan) &&  f.COUNTRY_BASE_PATH.strEq(this.basePath))?.COUNTRY_FLAG??"it"
    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 990px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    this.policyModeIubenda = environment.USE_IUBENDA;
    this.swVersion = environment.appVersion;
    this.cookieLink = `${this.basePath}/${this.tsvc.currentLanguage.value}/medias/getmedia?ContentId=cookie&ContentTypeId=policy`;
    this.privacyLink = `${this.basePath}/${this.tsvc.currentLanguage.value}/medias/getmedia?ContentId=privacy&ContentTypeId=policy`;
    this.subs.add(this.policySvc.publicPolicies.subscribe(s => {
      this.policies = (s.filter(f => f.pageName?.strEq('footer')))[0]?.policies ?? []
    }))

  }

  changeCountry() {
    this.modal.create(
      {
        nzTitle: this.tranPipe.transform("modal_change_country_title", "Cambia nazione?"),
        nzContent: this.tranPipe.transform("modal_change_country_subtitle", "Sei sicuro di voler cambiare nazione? assicurati di avere un account valido per la nazione che selezionerai", []),
        nzWidth: this.isMobile ? '80%' : '500px',
        nzClassName: 'ps-modal full-button',
        nzCentered: this.isMobile ? true : false,
        nzClosable: true,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("modal_change_country_button", "Cambia"),
        nzCancelDisabled: true,
        nzCancelText: null,
        nzZIndex: 1000,
        nzOnOk: async () => {
          localStorage.clear();
          window.location.reload();
        }
      }
    )
  }

}
