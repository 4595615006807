import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/default/environment';
import { ChangeEmailComponent } from './authentication/change-email/change-email.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { LoginComponent } from './authentication/login/login.component';
import { RegisterComponent } from './authentication/register/register.component';
import { RequestChangeEmailComponent } from './authentication/request-change-email/request-change-email.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { VerifyUserEmailComponent } from './authentication/verify-user-email/verify-user-email.component';
import { Error404Component } from './error/404/404.component';
import { RedeemReferralComponent } from './referral/redeem-referral/redeem-referral.component';
import { PrivateRegisterComponent } from './authentication/private-register/private-register.component';
import { LocalizationGuard } from 'src/core-modules/localization/localization.guard';
import { CountryGuard } from 'src/core-modules/country/country.guard';
import { TermsAndConditionsComponent } from './public/terms-and-conditions/terms-and-conditions.component';
import { SaleEventsPreviewComponent } from './public/sale-events-preview/sale-events-preview.component';
import { ContactsComponent } from './public/contacts/contacts.component';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';

function switchCompanyRedirect() {
  return environment.COMPANY_ABB == 'ke' || environment.COMPANY_ABB == 'swpr';
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'none-none/login',
    pathMatch: 'full',
  },
  { path: '404', component: Error404Component, canActivate: [CountryGuard] },
  {
    path: ':cultureId',
    canActivate: [CountryGuard, LocalizationGuard],
    children: [
      {
        path: '',
        redirectTo: switchCompanyRedirect() ? 'login' : 'register',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      switchCompanyRedirect()
        ? {
            path: 'register',
            pathMatch: 'full',
            redirectTo: 'login',
          }
        : {
            path: 'register',
            component: RegisterComponent,
          },
      {
        path: 'private-register',
        component: PrivateRegisterComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'request-change-email',
        component: RequestChangeEmailComponent,
      },
      {
        path: 'change-email',
        component: ChangeEmailComponent,
      },
      {
        path: 'contacts',
        component: ContactsComponent,
      },
      {
        path: 'private-sales-preview',
        component: SaleEventsPreviewComponent,
      },
      {
        path: 'referral',
        component: RedeemReferralComponent,
      },
      {
        path: 'verify-email',
        component: VerifyUserEmailComponent,
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
      {
        path: 'event',
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
          import('./private/private.module').then((m) => m.PrivateModule),
      },
    ],
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      //preloadingStrategy: PreloadAllModules
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}


