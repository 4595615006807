import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleEventDatePipe } from './custom-pipe/sale-event-date.pipe.';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SaleEventDatePipe],
  exports: [SaleEventDatePipe]
})
export class PurpleProjectWidgetsModule { }
