import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { lastValueFrom } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PRPVSaleEventFull, PurpleApiResponseStatus } from 'src/core-modules/sdk/api';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';
import { PurpleTranslationPipe, Helper, Guid } from 'purple-lib';
function switchCompanyRedirect() {
  return environment.COMPANY_ABB == 'ke' || environment.COMPANY_ABB == 'swpr';
}
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  validateForm!: UntypedFormGroup;

  constructor(public authSvc: AuthenticationService, private fb: UntypedFormBuilder, private tsvc: AppTranslationService, private route: ActivatedRoute, private seSvc: SaleEventService,
    private bookSvc: BookingService, private mesSvc: NzMessageService, private tranPipe: PurpleTranslationPipe, private layoutSvc: LayoutService, private router: Router, private notSvc: NzNotificationService) { 
      this.phonePrefixes = Helper.getPhonePrefixes(); 
      this.showAllSaleEventContacts = environment.SHOW_ALL_PRIVATE_SALE_CONTACTS;
    }

  subtitleText: string = this.tranPipe.transform("contact_subtitle", "con noi<span class='dot'>.</span>")

  currentSaleEvent: PRPVSaleEventFull | undefined;
  selectSaleEvents: PRPVSaleEventFull[] = [];
  guidEmpty = Guid.empty();
  phonePrefixes: string[] = [];
  showAllSaleEventContacts: boolean = true;
  isKering: boolean = switchCompanyRedirect()

  ngOnDestroy(): void {
    this.layoutSvc.setFullContent(true);
  }

  async ngOnInit(): Promise<void> {
    this.layoutSvc.setFullContent(false);

    this.validateForm = this.fb.group({
      saleEvent: [{value: null, disabled: !this.showAllSaleEventContacts}, [Validators.required]],
      fullName: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      phoneNumberPrefix: ['+33'],
      phoneNumber: [null],
      message: [null, [Validators.required]]
    });

    if (this.authSvc.isLoggedIn  && !this.authSvc.isAdminUser()) {
      await this.router.navigate([this.tsvc.currentLanguage.value,'event', 'contacts']);
    }

    this.seSvc.currentSaleEvent.next(undefined);
    this.seSvc.currentSaleEventUtc.next(undefined);

    this.route.queryParams
      .subscribe(params => {
        this.seSvc.getSaleEventParameters(params);
      });

    var sec: string | undefined = this.route.children[0]?.snapshot.params['saleEventCode'] ?? undefined;

    if (sec != undefined) {
      const resp = await lastValueFrom(this.bookSvc.getSaleEventBySaleEventCode(this.tsvc.currentLanguage.value, {
        saleEventCode: sec
      }));

      if (resp.status == PurpleApiResponseStatus.Success && resp.data != undefined) {
        this.currentSaleEvent = resp.data;
        this.selectSaleEvents.push(resp.data);
      } else {
        this.getBaseInfo()
      }
    } else {
      this.getBaseInfo()
    }
  }

  async getBaseInfo() {
    //console.log("Non trovo sec o se non valido");
    const resp = await lastValueFrom(this.bookSvc.getSaleEventBySaleEventId(this.tsvc.currentLanguage.value, {
      saleEventId: this.guidEmpty
    }));

    //console.log("Resp: ", resp)
    if (resp.status == PurpleApiResponseStatus.Success) {
      this.currentSaleEvent = resp.data
      this.selectSaleEvents.push(resp.data!);
    } else {
      this.currentSaleEvent = undefined
    }
  }


  async submitForm(): Promise<void> {

    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {

      const formResp = await lastValueFrom(this.bookSvc.sendContactFormMessage(this.tsvc.currentLanguage.value, {
        saleEvent: this.validateForm.controls["saleEvent"].value,
        email: this.validateForm.controls["email"].value,
        fullName: this.validateForm.controls["fullName"].value,
        phone: this.validateForm.controls["phoneNumber"].value,
        phonePrefix: this.validateForm.controls["phoneNumberPrefix"].value,
        message: this.validateForm.controls["message"].value,
        clientHost: window.location.hostname
      }));

      if (formResp.status == PurpleApiResponseStatus.Success) {
        this.mesSvc.success(this.tranPipe.transform("message_send_contact_form_ok_response", "Richiesta inviata correttamente"), {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.validateForm.clearValidators();
        this.validateForm.controls["message"].reset();
      } else {
        this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), this.tranPipe.transform("message_send_contact_form_ko_response", "Errore! Richiesta non inviata"), {nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top"});

      }
    }
  }



}
