import pkg from '../../../package.json';


export const environment = {
  appVersion: pkg.version,
  appName: pkg.name,
  production: true, 
  COMPANY_NAME: "Kering",
  COMPANY_ABB: "ke",
  SHOW_COUNTRY_SELECT: true, 
  COUNTRY_SELECT: [ 
    {
      COUNTRY_NAME: "Italia",
      COUNTRY_FLAG: "it",
      COUNTRY_BASE_PATH: "https://api-ke-it.saywow.me",
      COUNTRY_MEDIA_PATH: "https://media-ke-it.saywow.me",
      COUNTRY_DEFAULT_LANGUAGE: "en-gb"
    },
    {
      COUNTRY_NAME: "United States of America",
      COUNTRY_FLAG: "us",
      COUNTRY_BASE_PATH: "https://api-ke-us.saywow.me",
      COUNTRY_MEDIA_PATH: "https://media-ke-us.saywow.me",
      COUNTRY_DEFAULT_LANGUAGE: "en-us"
    },
    {
      COUNTRY_NAME: "Switzerland",
      COUNTRY_FLAG: "ch",
      COUNTRY_BASE_PATH: "https://api-ke-ch.saywow.me",
      COUNTRY_MEDIA_PATH: "https://media-ke-ch.saywow.me",
      COUNTRY_DEFAULT_LANGUAGE: "en-gb"
    },
    {
      COUNTRY_NAME: "France",
      COUNTRY_FLAG: "fr",
      COUNTRY_BASE_PATH: "https://api-ke-fr.saywow.me",
      COUNTRY_MEDIA_PATH: "https://media-ke-fr.saywow.me",
      COUNTRY_DEFAULT_LANGUAGE: "en-gb"
    }
  ],
  DEFAULT_PHONE_PREFIX : "+39",
  MESSAGE_DURATION : 2500,
  ERROR_MESSAGE_DURATION : 0,
  NEW_TRAN_SYMBOL: "",
  EDIT_TRAN_SYMBOL: "", 
  EDIT_TRAN_AFFIX: "booking_",
  ERROR_NOTIFICATION_DURATION : 0,  
  LOADER_DELAY : 200,
  SAFARI_COLOR : "#0d0128",
  HTML_THEME_COLOR : "#ffffff",
  HTML_TITLE_COLOR : "#0d0128", 
  REGISTRATION_FULL_ADDRESS: false,
  USE_IUBENDA: false,
  IUBENDA_COOKIE_POLICY: '',
  IUBENDA_CONS: '',
  IUBENDA_DEFAULT_HTML: '',
  SHOW_PUBLIC_EVENT: false,
  SHOW_EVENTS_SALE_NAME: false,
  SHOW_CHEK_IN_INFO: false,
  SHOW_ALL_PRIVATE_SALE_CONTACTS: false,
  REGISTER_TYPE: "full"
};
