<div *ngIf="saleEventDetail != undefined"
  class="booking-sale-detail-main-container ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center">

  <app-base-sale-event-detail-banner *ngIf="(saleEventDetail.saleEvent.saleEventDetailLayout??'base') == 'base'"
    class="ps-w-100" [saleEventDetail]="saleEventDetail" [scrollFn]="scrollTo"></app-base-sale-event-detail-banner>

  <app-advanced-sale-event-detail-banner *ngIf="(saleEventDetail.saleEvent.saleEventDetailLayout??'base') == 'avanzata'"
    class="ps-w-100" [saleEventDetail]="saleEventDetail"></app-advanced-sale-event-detail-banner>

  <container-element class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center"
    [ngSwitch]="saleEventDetail.bookingClosed">
    <ng-container *ngSwitchCase="false">
      <div class="ps-w-100 ps-justify-content-center ps-flex-wrap ps-d-none ps-d-md-flex">
        <div class="ps-w-100 ps-d-flex ps-justify-content-center">
          <div #bookingCalendarDaysContainer class="booking-calendar-days-container">
            <i *ngIf="!tooShort" (click)="slidePrev()" class="calendar-slider-arrow left-arrow" nz-icon
              nzType="arrow-left" nzTheme="outline"></i>
            <i *ngIf="!tooShort" (click)="slideNext()" class="calendar-slider-arrow right-arrow" nz-icon
              nzType="arrow-right" nzTheme="outline"></i>
            <swiper #daySlider [slideToClickedSlide]="false" (click)="slideClick(this, $event)" (swiper)="swiperInit()"
              [slidesPerView]="slidesForView" [centeredSlides]="false" [navigation]="false"
              [width]="this.slidesForView*120" [allowTouchMove]="false" [class]="'mySwiper ' + tooShort?'short':''"
              (slideChange)="onSlideChange()">
              <ng-template *ngFor="let daySlot of saleEventDetail!.daySlots" swiperSlide>
                <div
                  class="ps-cursor-pointer calendar-sale-event-item ps-d-flex ps-flex-wrap ps-align-items-center sw-border-radius">
                  <div class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-w-100">
                    <p class="calendar-sale-event-item-day ps-mb-2 ps-text-center ps-text-uppercase ps-w-100">
                      {{daySlot.slotDayDate | saleEventDate: 'EEE' }}
                    </p>
                    <p class="calendar-sale-event-item-date ps-text-center ps-mb-0 ps-w-100">
                      {{daySlot.slotDayDate | saleEventDate: 'shortDate'}}
                    </p>
                  </div>
                </div>
              </ng-template>
            </swiper>
          </div>
        </div>
        <div *ngIf="saleEventDetail!.daySlots.length > 0" class="booking-calendar-day-slots-container sw-border-radius">
          <ng-container *ngIf="isSwiperInit">

            <div nzType="default"
              (click)="showBookingModal(slot, saleEventDetail!.daySlots[this.swiper!.swiperRef.activeIndex].dayGmtInfo)"
              *ngFor="let slot of (saleEventDetail!.daySlots[this.swiper!.swiperRef.activeIndex]?.slots??[])" class="booking-calendar-day-slots-item sw-border-radius {{slot.bookedByUser?'booked':''}} 
              {{checkIfSlotIsFull(slot)?'sold-out':''}}">
              <p class="ps-w-100 ps-text-center slot-date">
                {{slot.slotStartDate | saleEventDate: 'shortTime' }} -
                {{slot.slotEndDate | saleEventDate: 'shortTime'}}
              </p>

              <div class="booking-calendar-day-slots-item-hover">
                <p class="ps-m-0 ps-text-upper fw-b" purpleEditableText tranKey='sold_out'>
                  <span *ngIf="checkIfSlotIsFull(slot)">{{ 'sold_out' | translation: 'sold out': []}}</span>
                </p>
              </div>

            </div>
          </ng-container>

        </div>
      </div>

      <div class="ps-w-100 ps-d-flex ps-d-md-none ps-justify-content-center invite-friend-button"
        *ngIf="checkCanInvite()">
        <button (click)="showInviteFriendsDrawer()" nz-button class="sw-border-radius" nzType="primary"
          purpleEditableText tranKey='invite_friend_detail_button'>
          {{ 'invite_friend_detail_button' | translation: 'Invita amici': []}}
        </button>
      </div>

      <div
        class="ps-w-100 ps-justify-content-center ps-flex-wrap ps-d-flex ps-d-md-none mobile-booking-calendar-days-container">
        <mat-expansion-panel *ngFor="let daySlot of saleEventDetail!.daySlots" [expanded]="panelActive(daySlot)"
          class="sw-border-radius">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div
                class="collapse-header ps-d-flex ps-w-100 ps-flex-wrap ps-align-items-center ps-justify-content-center">
                <p class="collapse-header-date-day ps-w-100 ps-text-center">{{daySlot.slotDayDate | saleEventDate: 'EEEE'}}</p>
                <p class="collapse-header-date-date ps-w-100 ps-m-0 ps-text-center">{{daySlot.slotDayDate | saleEventDate:
                  'shortDate'}}</p>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="collapse-content-container ps-d-flex ps-flex-wrap ps-w-100">

            <div class="ps-d-flex ps-col-6 booking-calendar-day-slots-item-container"
              *ngFor="let slot of daySlot.slots">

              <div (click)="showBookingDrawer(slot, daySlot.dayGmtInfo)" class="booking-calendar-day-slots-item disable-select sw-border-radius {{slot.bookedByUser?'booked-mobile':''}} 
                {{checkIfSlotIsFull(slot)?'sold-out':''}}">
                <p class="ps-w-100 ps-text-center slot-date disable-select">
                  {{slot.slotStartDate | saleEventDate: 'shortTime'}} -
                  {{slot.slotEndDate | saleEventDate: 'shortTime'}}
                </p>
                <div class="booking-calendar-day-slots-item-hover sw-border-radius">
                  <p class="ps-m-0 ps-text-upper fw-b" purpleEditableText tranKey='sold_out'>{{ 'sold_out' |
                    translation:
                    'sold out': []}}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="true">
      <div class="ps-w-100 ps-d-flex ps-flex-wrap booking-closed-container ps-justify-content-center">
        <h2 class="ps-w-100 ps-text-center booking-closed-title" purpleEditableText
          tranKey='sale_event_detail_closed_booking_closed_title'>
          {{ 'sale_event_detail_closed_booking_closed_title' | translation: 'Prenotazioni chiuse': []}}
        </h2>
        <p class="ps-w-100 ps-text-center booking-closed-subtitle" purpleEditableText
          tranKey='sale_event_detail_closed_booking_closed_subtitle'>
          {{ 'sale_event_detail_closed_booking_closed_subtitle' |
          translation: 'Le iscrizioni all\'evento sono chiuse, il riepilogo delle tue informazioni:': []}}
        </p>
        <div class="booking-closed-info">
          <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center">
            <p purpleEditableText tranKey='sale_event_detail_closed_booking_your_reservation_title'
              class="booking-closed-info-title ps-w-100">
              {{ 'sale_event_detail_closed_booking_your_reservation_title' | translation: 'La tua prenotazione': []}}
            </p>
            <container-element class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center"
              [ngSwitch]="(saleEventDetail.userSlotBooked??undefined) != undefined">
              <ng-container *ngSwitchCase="true">
                <p purpleEditableText class="booking-closed-info-value ps-w-100">
                  {{saleEventDetail.userSlotBooked!.slotStartDate | saleEventDate: "longDate"}}
                  {{saleEventDetail.userSlotBooked!.slotStartDate | saleEventDate: "shortTime"}} -
                  {{saleEventDetail.userSlotBooked!.slotEndDate | saleEventDate: "shortTime"}}
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p purpleEditableText purpleEditableText tranKey='sale_event_detail_closed_booking_no_booked_slot'
                  class="booking-closed-info-value ps-w-100">
                  {{ 'sale_event_detail_closed_booking_no_booked_slot' | translation: 'Nessuna prenotazione': []}}
                </p>
              </ng-container>
            </container-element>

            <container-element class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center"
              [ngSwitch]="invitedFriends.length > 0">
              <p purpleEditableText tranKey='sale_event_detail_closed_booking_your_guests_title'
                class="booking-closed-info-title guest ps-w-100">
                {{ 'sale_event_detail_closed_booking_your_guests_title' | translation: 'I tuoi invitati': []}}
              </p>
              <ng-container *ngSwitchCase="true">
                <p purpleEditableText class="booking-closed-info-value ps-w-100" *ngFor="let friend of invitedFriends">
                  {{friend.firstName}} {{friend.lastName}} ({{friend.email}})
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p purpleEditableText purpleEditableText tranKey='sale_event_detail_closed_booking_no_guests'
                  class="booking-closed-info-value ps-w-100">
                  {{ 'sale_event_detail_closed_booking_no_guests' | translation: 'Nessun invitato': []}}
                </p>
              </ng-container>
            </container-element>

          </div>
        </div>
      </div>
    </ng-container>
  </container-element>
</div>