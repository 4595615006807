<nz-footer class="purple-footer ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-end ps-justify-content-center ps-p-0">
  <div
    class="purple-footer-main-container ps-flex-wrap ps-d-flex ps-align-items-center ps-justify-content-center ps-pt-lg-0 ps-pt-4">
    <div class="ps-flex-wrap ps-d-flex ps-align-items-center ps-justify-content-center ps-w-100">

      <div
        class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center {{!isKering?'ps-justify-content-md-start':'ps-justify-content-md-center'}} {{!isKering?'ps-col-md-4':'ps-col-md-12'}} ps-col-12">
        <img contentId="logo-footer" contentTypeId="logo-company" purpleEditableImage class="purple-footer-logo" />
      </div>
      <div *ngIf="!isKering" class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center ps-col-md-4 ps-col-12">
        <!-- <p class="ps-w-100 ps-text-center">
        Copyright {{currentYear}} - {{companyName}}
      </p> -->
        <div class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center ps-py-lg-0 ps-py-4">
          <a purpleEditableText tranKey='ig_link' class="ps-mr-2" [href]="'ig_link' | translation: '#': []"
            style="font-size: 24px !important;">
            <i nz-icon nzType="instagram" nzTheme="outline"></i>
          </a>
          <a purpleEditableText tranKey='fb_link' [href]="'fb_link' | translation: '#': []" class="ps-ml-2"
            style="font-size: 24px !important;">
            <i nz-icon nzType="facebook" nzTheme="outline"></i>
          </a>
        </div>
      </div>
      <div
        class="ps-w-100 ps-d-md-flex ps-flex-wrap ps-align-items-center ps-justify-content-end {{!isKering?'ps-col-4':'ps-col-12'}} ps-d-none">
        <ng-container *ngFor="let policy of policies">
          <container-element [ngSwitch]="policy.policyTypeId" class="ps-w-100">
            <ng-container *ngSwitchCase="'iubenda'">
              <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-end">
                <a [href]="policy.policyUrl" target="_blank"
                  class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iubenda policy-label ps-my-1"
                  [title]="policy.policyTitle">
                  {{policy.policyTitle}}</a>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'media'">
              <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-end">
                <a class="ps-mb-0 policy-label"
                  [href]="basePath + '/' + tsvc.currentLanguage.value + '/medias/getmedia?ContentId=' + policy.policyContentId + '&ContentTypeId=' + policy.policyContentTypeId">
                  {{policy.policyTitle}}
                </a>
              </div>
            </ng-container>
          </container-element>
        </ng-container>
      </div>
      <div *ngIf="showCountrySelect"
        class="ps-flex-wrap ps-d-flex ps-align-items-center ps-justify-content-center ps-w-100">
        <p class="country-select"><span purpleEditableText tranKey='current_country'>{{ 'current_country' | translation:
            'Nazione corrente': []}}</span><span class="fi fi-{{countryFlag}} flag"></span>
          <span (click)="changeCountry()" class="click-here" purpleEditableText tranKey='click_here_to_edit'>{{
            'click_here_to_edit' | translation: 'clicca qui': []}}</span>
          <span purpleEditableText tranKey='current_country_edit'>{{ 'current_country_edit' | translation: 'per
            modificare': []}}</span>
        </p>
      </div>

    </div>
  </div>

  <div
    class="purple-footer-secondary-container ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center ps-py-3">
    <div
      class="mobile-cookie-container ps-w-100 ps-d-flex ps-d-md-none ps-justify-content-center ps-align-items-center ps-mt-3">

      <ng-container *ngFor="let policy of policies">
        <container-element [ngSwitch]="policy.policyTypeId">
          <ng-container *ngSwitchCase="'iubenda'">
            <a [href]="policy.policyUrl" target="_blank"
              class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iubenda"
              [title]="policy.policyTitle">
              {{policy.policyTitle}}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'media'">
            <a
              [href]="basePath + '/' + tsvc.currentLanguage.value + '/medias/getmedia?ContentId=' + policy.policyContentId + '&ContentTypeId=' + policy.policyContentTypeId">
              {{policy.policyTitle}}
            </a>
          </ng-container>
        </container-element>
      </ng-container>


      <!-- <ng-container *ngIf="!policyModeIubenda">
      <a [href]="cookieLink" class="p-mr-3" purpleEditableText tranKey='privacy_policy'>
        {{ 'privacy_policy' | translation: 'Privacy Policy': []}}
      </a>
      <a [href]="privacyLink" class="ps-ml-3" purpleEditableText tranKey='cookie_policy'>
        {{ 'cookie_policy' | translation: 'Cookie Policy': []}}
      </a>
    </ng-container>

    <ng-container *ngIf="policyModeIubenda">

      <a href="https://www.iubenda.com/privacy-policy/81469193"
        class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iubenda p-mr-3"
        [title]="'privacy_policy' | translation: 'Privacy Policy': []">
        {{ 'privacy_policy' | translation: 'Privacy Policy': []}}</a>
        <a href="https://www.iubenda.com/privacy-policy/81469193/cookie-policy"
        class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iubenda ps-ml-3"
        [title]="'cookie_policy' | translation: 'Cookie Policy': []">
        {{ 'cookie_policy' | translation: 'Cookie Policy': []}}</a>
    </ng-container> -->
    </div>
    <div *ngIf="!isKering" class="ps-w-100 ps-text-center">Made with
      <span style="cursor: default;" nzPopoverTrigger="click" nz-popover nzPopoverPlacement="top"
        [nzPopoverContent]="swVersion" class="fsp-18-0 ps-purple-color">♥</span> by&nbsp;
      <a class="ps-href-reset ps-purple-color" target="_blank" href="https://www.purplesoft.io">Purplesoft</a>
    </div>
    <!-- <p class="sw-version">V.{{swVersion}}</p> -->

  </div>
</nz-footer>