import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/layout.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { BookingService, PolicyQuery, PurpleApiResponseStatus, User, UserAttributeValuesItem } from 'src/core-modules/sdk/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/default/environment';
import { Subscription, lastValueFrom } from 'rxjs';
import { SaleEventPoliciesQuery } from 'src/core-modules/sdk/api/model/saleEventPoliciesQuery';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EditUserAttributesModalComponent } from '../modals/edit-user-attributes-modal/edit-user-attributes-modal.component';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { CheckUserAttributesDrawerComponent } from '../drawers/check-user-attributes-drawer/check-user-attributes-drawer.component';
import { ShowPolicyPreviewModalComponent } from '../modals/show-policy-preview-modal/show-policy-preview-modal.component';
import { ShowPolicyPreviewDrawerComponent } from '../drawers/show-policy-preview-drawer/show-policy-preview-drawer.component';
import { StorageService } from 'src/core-modules/storage/storage.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Helper, PurpleLoaderService, PurpleSelectFilter, PurpleTranslationPipe } from 'purple-lib';
function switchCompanyRedirect() {
  return environment.COMPANY_ABB == 'ke' || environment.COMPANY_ABB == 'swpr';
}
@Component({
  selector: 'app-private-user-profile',
  templateUrl: './private-user-profile.component.html',
  styleUrls: ['./private-user-profile.component.scss']
})
export class PrivateUserProfileComponent implements OnInit {

  constructor(private layoutSvc: LayoutService, private breakpointObserver: BreakpointObserver, private fb: UntypedFormBuilder, public tsvc: AppTranslationService, private loaderSvc: PurpleLoaderService, public policySvc: PolicyAndUserAttributesService,
    private authSvc: AuthenticationService, private mesSvc: NzMessageService, private tranPipe: PurpleTranslationPipe, public bookSvc: BookingService, private authService: AuthenticationService, private modal: NzModalService, 
    private drawerSvc: NzDrawerService, private storageSvc: StorageService, private notSvc:NzNotificationService) {
    this.phonePrefixes = Helper.getPhonePrefixes();
  }

  isMobile: boolean = false;
  validateForm: FormGroup = new FormGroup({});
  phonePrefixes: string[] = [];
  currentUser!: User;
  currentExternalAddress: string | undefined;
  refreshAddressSelect: number = 0;
  externalAddress: string | undefined;
  fullAddressPlaceholder: string = "";
  fullAddressTitle: string = "";
  regFullAdd = environment.REGISTRATION_FULL_ADDRESS;
  isFormEnable: boolean = false;
  isLoading: boolean = false;
  isLoadingResetPass: boolean = false;
  saleEventPolicies: SaleEventPoliciesQuery[] = [];
  userAttributes: UserAttributeValuesItem[] = [];

  refreshAddress: number = 0;
  isKering = switchCompanyRedirect();
  subs: Subscription = new Subscription();


  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.layoutSvc.setFullContent(true);
  }

  async ngOnInit() {
    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 650px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    this.loaderSvc.addRequest("general");
    this.fullAddressPlaceholder = this.regFullAdd ? this.tranPipe.transform('register_form_address', 'Indirizzo (Opzionale)', []) : this.tranPipe.transform('register_form_city', 'Città (Opzionale)', [])
    this.fullAddressTitle = this.regFullAdd ? this.tranPipe.transform('edit_profile_form_address_title_full', 'Indirizzo', []) : this.tranPipe.transform('edit_profile_form_address_title_city', 'Città', [])
    this.layoutSvc.setFullContent(false);
    
    this.validateForm = this.fb.group({
      firstName: [{ disabled: !this.isFormEnable, value: "" }, [Validators.required]],
      lastName: [{ disabled: !this.isFormEnable, value: "" }, [Validators.required]],
      email: [{ disabled: !this.isFormEnable, value: "" }, [Validators.required]],
      birthdate: [{ disabled: !this.isFormEnable, value: null }],
      gender: [{ disabled: !this.isFormEnable, value: null }],
      phoneNumberPrefix: [{ disabled: !this.isFormEnable, value: null }],
      phoneNumber: [{ disabled: !this.isFormEnable, value: null }],
      address: [{ disabled: !this.isFormEnable, value: null }]
    });
    //this.restoreForm(true);

    

    this.currentUser = Helper.storeNewObj(this.authSvc.currentUser!.user!);
    //this.restoreForm();


    const res = await lastValueFrom(this.bookSvc.getUserRegistrationAddress(this.tsvc.currentLanguage.value))
    this.externalAddress = res.data?.addressExternalId ?? undefined;

    this.currentExternalAddress = Helper.storeNewObj(this.externalAddress);
    this.refreshAddressSelect++;
    //console.log("CURRENT ADDRESS: ", this.currentExternalAddress)
    this.restoreForm();

    await this.getUserPolicies();
    await this.getUserAttributes();
    this.loaderSvc.removeRequest("general");
  }


  async submitForm() {
    this.isLoading = true;
    this.validateForm.controls["address"].setValue(this.currentExternalAddress);

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.currentUser.firstName = this.validateForm.controls["firstName"].value;
      this.currentUser.lastName = this.validateForm.controls["lastName"].value;
      this.currentUser.phone = this.validateForm.controls["phoneNumber"].value;
      this.currentUser.phonePrefix = this.validateForm.controls["phoneNumberPrefix"].value;
      this.currentUser.birthDate = this.validateForm.controls["birthdate"].value;
      this.currentUser.genderId = this.validateForm.controls["gender"].value;

      //console.log("Indirizzo attuale: ", this.externalAddress, " - Nuovo Indirizzo: ", this.currentExternalAddress, " - Sono diversi: ", Helper.checkIfDoChanges(this.currentExternalAddress, this.externalAddress))

      if (Helper.checkIfDoChanges(this.currentUser, this.authSvc.currentUser!.user!) || Helper.checkIfDoChanges(this.currentExternalAddress, this.externalAddress)) {
        const res = await lastValueFrom(this.bookSvc.updateBaseUserInfo(this.tsvc.currentLanguage.value, {
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
          birthDate: this.currentUser.birthDate,
          genderId: this.currentUser.genderId,
          phone: this.currentUser.phone,
          phonePrefix: this.currentUser.phonePrefix,
          externalAddress: this.currentExternalAddress
        }))

        if (res.status!.toLowerCase() == PurpleApiResponseStatus.Success.toLowerCase()) {
          this.mesSvc.success(this.tranPipe.transform('update_base_user_info_ok', 'Informazioni aggiornate con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.isLoading = false;
          this.authSvc.currentUser!.user = res.data;
          this.storageSvc.set('CurrentUser', this.authSvc.currentUser);
          this.currentUser = Helper.storeNewObj(this.authSvc.currentUser!.user!)
          this.isFormEnable = false;
          this.restoreForm();
          this.validateForm.disable();
        } else {
          this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), res.message, {nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top"});
          this.isLoading = false;
        }
      } else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
        this.isLoading = false;
      }
    }

    this.isLoading = false;
  }

  enableForm() {
    this.isFormEnable = true;
    this.validateForm.enable();

    this.validateForm.controls["email"].disable();
  }

  cancelEdit() {
    this.validateForm.reset();
    this.isFormEnable = false;
    this.restoreForm();
    this.validateForm.disable();
  }


  restoreForm() { 
    this.validateForm.controls["firstName"].setValue(this.currentUser.firstName);
    this.validateForm.controls["lastName"].setValue(this.currentUser.lastName); 
    this.validateForm.controls["email"].setValue(this.currentUser.email);
    this.validateForm.controls["birthdate"].setValue(this.currentUser.birthDate);
    this.validateForm.controls["gender"].setValue(this.currentUser.genderId); 
    this.validateForm.controls["phoneNumberPrefix"].setValue(this.currentUser.phonePrefix);
    this.validateForm.controls["phoneNumber"].setValue(this.currentUser.phone);
    this.validateForm.controls["address"].setValue(this.currentExternalAddress); 
  }


  requestResetPassword() {
    this.isLoadingResetPass = true;
    this.authService.requestResetPassword(this.currentUser.email, window.location.hostname, this.isMobile).then(
      async (isOk: boolean) => {
        if (isOk) {
          this.mesSvc.success(this.tranPipe.transform('req_reset_password_ok', 'Richiesta inviata con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.isLoadingResetPass = false;
        }
        else {
          this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), this.tranPipe.transform('req_reset_password_error', 'Errore, riprovare!', []), {nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top"});
         
          this.isLoadingResetPass = false;
        }
      }
    )
  }

  async getUserPolicies() {
    const res = await lastValueFrom(this.bookSvc.getUserPolicies(this.tsvc.currentLanguage.value))

    if (res.status!.toLowerCase() == PurpleApiResponseStatus.Success.toLowerCase()) {
      this.saleEventPolicies = res.data!
    } else {
      this.saleEventPolicies = []
    }
  }


  async getUserAttributes() {
    const res = await lastValueFrom(this.bookSvc.getUserAttributes(this.tsvc.currentLanguage.value))

    if (res.status!.toLowerCase() == PurpleApiResponseStatus.Success.toLowerCase()) {
      this.userAttributes = res.data!
    } else {
      this.userAttributes = []
    }
  }

  async updateSaleEventUserPolicyEndorsement(value: boolean, policy: PolicyQuery) {
    //console.log("CAMBIOOO")
    const res = await lastValueFrom(this.bookSvc.updateSaleEventUserPolicyEndorsement(this.tsvc.currentLanguage.value, {
      saleEventId: policy.saleEventId,
      accepted: value,
      policyId: policy.policyId
    }));

    if (res.status == PurpleApiResponseStatus.Success) {
      this.mesSvc.success(this.tranPipe.transform('update_sale_event_user_policy_endsorsment_ok', 'Aggiornato con successo', []), {
        nzDuration: environment.MESSAGE_DURATION
      });
    } else {
      this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), res.message, {nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top"});
      
    }
  }

  //#region Address

  addressSearchNameFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByNameSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByNameSelect(args.culture, args)
    }
  }

  addressSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if (environment.REGISTRATION_FULL_ADDRESS) {
      return this.bookSvc.getAddressByIdSelect(args.culture, args)
    } else {
      return this.bookSvc.getCityByIdSelect(args.culture, args)
    }
  }
  //#endregion



  policyAccepted(policy: PolicyQuery): boolean {
    if (policy.isMandatoryPolicy) {
      return true;
    }

    if (policy.policyPage.strEq("footer")) {
      return true;
    }

    if (policy.accepted) {
      return true;
    }

    return false;
  }

  policyBlocked(policy: PolicyQuery): boolean {
    if (policy.isMandatoryPolicy) {
      return true;
    }

    if (policy.policyPage.strEq("footer")) {
      return true;
    }

    return false;
  }

  async removePreference(userAttributeValueId: string) {
    const res = await lastValueFrom(this.bookSvc.removeUserAttributeValue(this.tsvc.currentLanguage.value, {
      userAttributeValueId: userAttributeValueId
    }));

    if (res.status == PurpleApiResponseStatus.Success) {
      this.mesSvc.success(this.tranPipe.transform('remove_attribute_value_ok', 'Preferenza rimossa con successo', []), {
        nzDuration: environment.MESSAGE_DURATION
      });
      await this.getUserAttributes();
    } else {
      this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), res.message, {nzDuration: environment.ERROR_NOTIFICATION_DURATION, nzPlacement: "top"});
      
    }
  }

  addPreference(isMobile: boolean = false) {
    if (isMobile) {
      this.drawerSvc.create<CheckUserAttributesDrawerComponent, { value: string }, string>({
        nzTitle: this.tranPipe.transform("drawer_check_user_attribute_policy_error_title", "Esprimi le tue preferenze", []),
        nzContent: CheckUserAttributesDrawerComponent,
        nzClosable: true,
        nzWrapClassName: "check-user-attributes",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          userAttributes: this.userAttributes
        }
      }).afterClose.subscribe(async s => {
        await this.getUserAttributes();
      });
    } else {
      this.modal.create<EditUserAttributesModalComponent, UserAttributeValuesItem[]>({
        nzTitle: this.tranPipe.transform("modal_check_user_attribute_policy_error_title", "Esprimi le tue preferenze", []),
        nzContent: EditUserAttributesModalComponent,
        nzData: this.userAttributes,
        nzWidth: '800px',
        nzClassName: 'ps-modal',
        nzFooter: null,
        nzCentered: true,
        nzMaskClosable: false
      }).afterClose.subscribe(async s => {
        await this.getUserAttributes();
      });
    }

  }

  showPolicyPreview(policy: PolicyQuery, isMobile: boolean = false) {
    if (isMobile) {
      this.drawerSvc.create<ShowPolicyPreviewDrawerComponent, { value: string }, string>({
        nzTitle: this.tranPipe.transform("drawer_policy_preview_title", "Policy Preview", []),
        nzContent: ShowPolicyPreviewDrawerComponent,
        nzClosable: true,
        nzWrapClassName: "show-policy-preview",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          policy: policy
        }
      })
    } else {
      this.modal.create<ShowPolicyPreviewModalComponent, PolicyQuery>({
        nzTitle: this.tranPipe.transform("modal_policy_preview_title", "Policy Preview", []),
        nzContent: ShowPolicyPreviewModalComponent,
        nzData: policy,
        nzWidth: '800px',
        nzClassName: 'ps-modal',
        nzFooter: null,
        nzCentered: true,
        nzMaskClosable: false
      });
    }
  }
}
