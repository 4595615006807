<div
  class="my-reservations-main-container ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center ps-align-items-start">
  <div class="my-reservations-external-container ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-start">
    <div class="my-reservations-title-container">
      <h1 class="ps-w-100 ps-d-none ps-d-lg-block purple-title" purpleEditableText tranKey='my_reservations_title'>
        {{ 'my_reservations_title' | translation: 'Le mie prenotazioni': []}}</h1>
      <h2 class="ps-w-100 ps-m-0 ps-d-none ps-d-lg-block purple-subtitle" purpleEditableText
        tranKey='my_reservations_subtitle' [innerHtml]="subtitleText! | safeHtml">
      </h2>
      <h2 class="ps-w-100 ps-m-0 ps-d-block ps-d-lg-none purple-title" purpleEditableText
        tranKey='my_reservations_title_mobile'>
        {{ 'my_reservations_title_mobile' | translation: 'Le mie prenotazioni': []}}
      </h2>
    </div>
    <div class="my-reservations-tab-container ps-w-100 ps-d-flex ps-flex-wrap">
      <nz-tabset>
        <nz-tab [nzTitle]="tabTitleNext">
          <div class="my-reservations-tab ps-w-100 ps-d-none ps-d-lg-flex ps-flex-wrap">
            <ng-container *ngIf="nextBookings.length > 0; else noNextBooking">
              <div *ngFor="let book of nextBookings" class="my-reservations-item-box-container ps-w-100 ps-d-flex">
                <div class="my-reservations-item-info-box-col ps-col-8">
                  <div class="my-reservations-item-info-box ps-d-flex ps-flex-wrap ps-w-100">
                    <div class="ps-w-100 ps-d-flex ps-align-items-center ps-mb-3">

                      <div class="my-reservations-item-info-box-sale-event-img-container">
                        <div contentId="{{book.saleEventId}}-logo" contentTypeId="sale-event-logo" purpleEditableImage
                          class="my-reservations-item-info-box-sale-event-img"></div>
                      </div>


                      <div
                        class="ps-d-flex ps-flex-wrap ps-w-100 my-reservations-item-info-box-sale-event-info-container">
                        <h1 class="my-reservations-item-info-box-sale-event-name ps-w-100 ps-m-0">{{book.saleEventName}}
                        </h1>
                        <h3
                          class="my-reservations-item-info-box-sale-event-address ps-d-flex ps-align-items-center ps-w-100">
                          <i nz-icon nzType="home" nzTheme="outline"></i>
                          {{book.address}}
                        </h3>
                      </div>


                    </div>

                    <div
                      class="my-reservations-item-info-box-sale-event-slot ps-d-flex ps-align-items-center ps-justify-content-between ps-w-100">
                      <p class="ps-m-0 date">
                        <span>{{book.slot.slotStartDate | saleEventDate:"EEE" }}</span>
                        {{book.slot.slotStartDate | saleEventDate:"dd MMM" }}
                      </p>
                      <p class="ps-m-0 hours">{{book.slot.slotStartDate | saleEventDate:"shortTime"}}
                        <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                        {{book.slot.slotEndDate | saleEventDate:"shortTime"}}
                      </p>
                      <i nz-popover nzPopoverPlacement="right" [nzPopoverContent]="book.slot.roleName" class="star"
                        nz-icon nzType="tag" nzTheme="twotone" [nzTwotoneColor]="'#EBBF3E'"></i>
                    </div>
                    <nz-collapse class="ps-w-100 manage-reservation-collapse">
                      <nz-collapse-panel [nzShowArrow]="false" [nzHeader]="collapseHeader" #datePanel>
                        <ng-template #collapseHeader let-active>
                          <div
                            class="collapse-header ps-d-flex ps-w-100 ps-flex-wrap ps-align-items-center ps-justify-content-between">
                            <p purpleEditableText tranKey='my_reservations_manage_reservation'
                              class="collapse-header-title ps-m-0">{{ 'my_reservations_manage_reservation' |
                              translation: 'Gestisci la prenotazione': []}}</p>
                            <i nz-icon nzType="right" class="ant-collapse-arrow"
                              [nzRotate]="datePanel.nzActive ? 90 : 0"></i>
                          </div>
                        </ng-template>
                        <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                          <a *ngIf="showDeleteButton(book)"
                            [routerLink]="['/', tsvc.currentLanguage.value, 'event', 'detail', book.saleEventCode]"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="sync" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_edit_reservation' class="ps-mb-0">{{
                              'my_reservations_edit_reservation' | translation: 'Modifica Prenotazione': []}}</p>
                          </a>
                          <a [href]="addToCalendar(book)"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="calendar" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_add_to_calendar' class="ps-mb-0">{{
                              'my_reservations_add_to_calendar' | translation: 'Aggiungi al Calendario': []}}</p>
                          </a>
                          <a (click)="showInviteFriendsModal(book)" *ngIf="showInviteButton(book)"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="team" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_share_reservation' class="ps-mb-0">{{
                              'my_reservations_share_reservation' | translation: 'Invita amico': []}}</p>
                          </a>
                          <a (click)="showQrCodeModal()"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="qrcode" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_qr_code' class="ps-mb-0">{{
                              'my_reservations_qr_code' | translation: 'Qr-Code Accesso': []}}</p>
                          </a>
                          <a (click)="unbook(book, false)" *ngIf="showDeleteButton(book)"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="delete" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_delete_reservation' class="ps-mb-0">{{
                              'my_reservations_delete_reservation' | translation: 'Cancella Prenotazione': []}}</p>
                          </a>
                        </div>

                      </nz-collapse-panel>
                    </nz-collapse>
                  </div>
                </div>
                <div class="my-reservations-item-ticket-box-col ps-col-4">
                  <div class="triangle"></div>
                  <div class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100">
                    <div class="my-reservations-item-ticket-box ps-d-flex ps-flex-wrap ps-w-100">

                      <h1 class="my-reservations-item-ticket-box-title ps-w-100 ps-m-0" purpleEditableText
                        tranKey='my_reservations_your_tickets'>
                        {{ 'my_reservations_your_tickets' | translation: 'I tuoi biglietti': []}}
                      </h1>
                      <div
                        class="ticket-box ps-d-flex ps-justify-content-start ps-flex-wrap ps-align-items-center ps-w-100">
                        <div class="ps-w-100 ps-d-flex ps-align-items-center">
                          <i class="fsp-18-0 ps-mr-3" nz-icon nzType="printer" nzTheme="outline"></i>
                          <a purpleEditableText tranKey='my_reservations_show_tickets' [href]="pdfSrcNext(book)"
                            class="fsp-14-5">{{ 'my_reservations_show_tickets' | translation: 'Visualizza biglietto':
                            []}}</a>
                        </div>
                        <div *ngIf="showCheckInInfo" class="ps-w-100 ps-d-flex ps-align-items-center ps-pt-3">
                          <i class="fsp-18-0 ps-mr-3" nz-icon nzType="info-circle" nzTheme="outline"></i>
                          <a (click)="showInstructionsModal()" purpleEditableText tranKey='my_reservations_informations'
                            class="fsp-14-5">{{ 'my_reservations_informations' | translation: 'Informazioni utili':
                            []}}</a>
                        </div>
                      </div>
                      <p purpleEditableText tranKey='my_reservations_disclaimer'
                        class="ps-mb-0 my-reservations-item-ticket-box-disclaimer">{{ 'my_reservations_disclaimer' |
                        translation: "L'accesso è consentito solo nelle fascie orarie prenotate": []}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="my-reservations-tab-mobile ps-w-100 ps-d-flex ps-d-lg-none ps-flex-wrap">
            <ng-container *ngIf="nextBookings.length > 0; else noNextBookingMobile">
              <div *ngFor="let book of nextBookings" class="my-reservations-item-box-container ps-w-100 ps-d-flex">
                <div class="my-reservations-item-info-box-col ps-w-100">
                  <div class="my-reservations-item-info-box ps-d-flex ps-flex-wrap ps-w-100">
                    <div class="ps-w-100 ps-d-flex ps-align-items-center ps-mb-3">
                      <img contentId="{{book.saleEventId}}-logo" contentTypeId="sale-event-logo" purpleEditableImage
                        class="my-reservations-item-info-box-sale-event-img" />

                      <div class="ps-d-flex ps-flex-wrap ps-w-100">
                        <h1 class="my-reservations-item-info-box-sale-event-name ps-m-0">{{book.saleEventName}}
                        </h1>
                        <h3 class="my-reservations-item-info-box-sale-event-address">
                          <i nz-icon nzType="home" nzTheme="outline"></i>
                          {{book.address}}
                        </h3>
                      </div>
                    </div>

                    <nz-tabset>
                      <nz-tab [nzTitle]="mobileTemplateBook">
                        <ng-template #mobileTemplateBook purpleEditableText tranKey='my_reservations_slots_tab'>
                          <i nz-icon nzType="environment" nzTheme="outline"></i>
                          {{ 'my_reservations_slots_tab' | translation: 'Prenotazioni': []}}
                        </ng-template>
                        <div
                          class="my-reservations-item-info-box-sale-event-slot ps-d-flex ps-align-items-center ps-justify-content-between ps-w-100">
                          <p class="ps-m-0 date"><span>{{book.slot.slotStartDate | saleEventDate:"EEE"}}</span><br>
                            {{book.slot.slotStartDate |
                            saleEventDate:"dd MMM"}}</p>
                          <p class="ps-m-0 hours">{{book.slot.slotStartDate | saleEventDate:"shortTime" }}
                            <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                            {{book.slot.slotEndDate | saleEventDate:"shortTime" }}
                          </p>
                          <i nz-popover nzPopoverPlacement="right" [nzPopoverContent]="book.slot.roleName" class="star"
                            nz-icon nzType="tag" nzTheme="twotone" [nzTwotoneColor]="'#EBBF3E'"></i>
                        </div>
                      </nz-tab>
                      <nz-tab [nzTitle]="mobileTemplateTicket">
                        <ng-template #mobileTemplateTicket purpleEditableText tranKey='my_reservations_tickets_tab'>
                          <i nz-icon nzType="barcode" nzTheme="outline"></i>
                          {{ 'my_reservations_tickets_tab' | translation: 'Biglietti': []}}
                        </ng-template>
                        <div
                          class="ticket-box ps-d-flex ps-justify-content-start ps-flex-wrap ps-align-items-center ps-w-100">
                          <div class="ps-w-100 ps-d-flex ps-align-items-center">
                            <i class="fsp-18-0 ps-mr-3" nz-icon nzType="printer" nzTheme="outline"></i>
                            <a [href]="pdfSrcNext(book)" class="fsp-14-5" purpleEditableText
                              tranKey='my_reservations_show_tickets'>{{ 'my_reservations_show_tickets' | translation:
                              'Visualizza biglietto': []}}</a>
                          </div>
                          <div *ngIf="showCheckInInfo" class="ps-w-100 ps-d-flex ps-align-items-center ps-pt-3">
                            <i class="fsp-18-0 ps-mr-3" nz-icon nzType="info-circle" nzTheme="outline"></i>
                            <a (click)="showInstructionsDrawer()" purpleEditableText
                              tranKey='my_reservations_informations' class="fsp-14-5">{{ 'my_reservations_informations'
                              | translation: 'Informazioni utili': []}}</a>
                          </div>
                        </div>
                        <p purpleEditableText tranKey='my_reservations_disclaimer'
                          class="ps-mb-0 my-reservations-item-ticket-box-disclaimer">{{ 'my_reservations_disclaimer' |
                          translation: "L'accesso è consentito solo nelle fascie orarie prenotate": []}}</p>
                      </nz-tab>
                    </nz-tabset>





                    <nz-collapse class="ps-w-100 manage-reservation-collapse">
                      <nz-collapse-panel [nzShowArrow]="false" [nzHeader]="collapseHeader" #datePanel>
                        <ng-template #collapseHeader let-active>
                          <div
                            class="collapse-header ps-d-flex ps-w-100 ps-flex-wrap ps-align-items-center ps-justify-content-between">
                            <p purpleEditableText tranKey='my_reservations_manage_reservation'
                              class="collapse-header-title ps-m-0">{{ 'my_reservations_manage_reservation' |
                              translation: 'Gestisci la prenotazione': []}}</p>
                            <i nz-icon nzType="right" class="ant-collapse-arrow"
                              [nzRotate]="datePanel.nzActive ? 90 : 0"></i>
                          </div>
                        </ng-template>
                        <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                          <a *ngIf="showDeleteButton(book)"
                            [routerLink]="['/', tsvc.currentLanguage.value, 'event', 'detail', book.saleEventCode]"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="sync" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_edit_reservation' class="ps-mb-0">{{
                              'my_reservations_edit_reservation' | translation: 'Modifica Prenotazione': []}}</p>
                          </a>
                          <a [href]="addToCalendar(book)"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="calendar" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_add_to_calendar' class="ps-mb-0">{{
                              'my_reservations_add_to_calendar' | translation: 'Aggiungi al Calendario': []}}</p>
                          </a>
                          <a (click)="showInviteFriendsDrawer(book)" *ngIf="showInviteButton(book)"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="team" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_share_reservation' class="ps-mb-0">{{
                              'my_reservations_share_reservation' | translation: 'Invita amico': []}}</p>
                          </a>
                          <a (click)="showQrCodeDrawer()"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="qrcode" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_qr_code' class="ps-mb-0">{{
                              'my_reservations_qr_code' | translation: 'Qr-Code Accesso': []}}</p>
                          </a>
                          <a (click)="unbook(book, true)" *ngIf="showDeleteButton(book)"
                            class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center manage-reservation-button">
                            <i nz-icon nzType="delete" nzTheme="outline"></i>
                            <p purpleEditableText tranKey='my_reservations_delete_reservation' class="ps-mb-0">{{
                              'my_reservations_delete_reservation' | translation: 'Cancella Prenotazione': []}}</p>
                          </a>
                        </div>

                      </nz-collapse-panel>
                    </nz-collapse>
                  </div>
                </div>

              </div>
            </ng-container>
          </div>
        </nz-tab>
        <nz-tab [nzTitle]="tabTitlePast">
          <div class="my-reservations-tab ps-w-100 ps-d-none ps-d-lg-flex ps-flex-wrap">
            <ng-container *ngIf="pastBookings.length > 0; else noNextBooking">
              <div *ngFor="let book of pastBookings" class="my-reservations-item-box-container ps-w-100 ps-d-flex">
                <div class="my-reservations-item-info-box-col ps-col-8">
                  <div class="my-reservations-item-info-box ps-d-flex ps-flex-wrap ps-w-100">
                    <div class="ps-w-100 ps-d-flex ps-align-items-center ps-mb-3">

                      <div class="my-reservations-item-info-box-sale-event-img-container">
                        <div contentId="{{book.saleEventId}}-logo" contentTypeId="sale-event-logo" purpleEditableImage
                          class="my-reservations-item-info-box-sale-event-img"></div>
                      </div>


                      <div
                        class="ps-d-flex ps-flex-wrap ps-w-100 my-reservations-item-info-box-sale-event-info-container">
                        <h1 class="my-reservations-item-info-box-sale-event-name ps-w-100 ps-m-0">{{book.saleEventName}}
                        </h1>
                        <h3
                          class="my-reservations-item-info-box-sale-event-address ps-d-flex ps-align-items-center ps-w-100">
                          <i nz-icon nzType="home" nzTheme="outline"></i>
                          {{book.address}}
                        </h3>
                      </div>


                    </div>

                    <div *ngFor="let slot of book.slots"
                      class="my-reservations-item-info-box-sale-event-slot ps-d-flex ps-align-items-center ps-justify-content-between ps-w-100">
                      <p class="ps-m-0 date"><span>{{slot.slotStartDate | saleEventDate:"EEE" }}</span>
                        {{slot.slotStartDate | saleEventDate:"dd MMM"}}</p>
                      <p class="ps-m-0 hours">{{slot.slotStartDate | saleEventDate:"shortTime"}}
                        <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                        {{slot.slotEndDate | saleEventDate:"shortTime"}}
                      </p>
                      <i nz-popover nzPopoverPlacement="right" [nzPopoverContent]="slot.roleName" class="star" nz-icon
                        nzType="tag" nzTheme="twotone" [nzTwotoneColor]="'#EBBF3E'"></i>
                    </div>

                  </div>
                </div>
                <div class="my-reservations-item-ticket-box-col ps-col-4">
                  <div class="triangle"></div>
                  <div class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100">
                    <div class="my-reservations-item-ticket-box ps-d-flex ps-flex-wrap ps-w-100">

                      <h1 purpleEditableText tranKey='my_reservations_your_tickets'
                        class="my-reservations-item-ticket-box-title ps-w-100 ps-m-0">
                        {{ 'my_reservations_your_tickets' | translation: 'I tuoi biglietti': []}}
                      </h1>
                      <div
                        class="ticket-box ps-d-flex ps-justify-content-start ps-flex-wrap ps-align-items-center ps-w-100">
                        <div class="ps-w-100 ps-d-flex ps-align-items-center">
                          <i class="fsp-18-0 ps-mr-3" nz-icon nzType="printer" nzTheme="outline"></i>
                          <a purpleEditableText tranKey='my_reservations_show_tickets' [href]="pdfSrcPast(book)"
                            class="fsp-14-5">{{ 'my_reservations_show_tickets' | translation: 'Visualizza biglietto':
                            []}}</a>
                        </div>
                        <div *ngIf="showCheckInInfo" class="ps-w-100 ps-d-flex ps-align-items-center ps-pt-3">
                          <i class="fsp-18-0 ps-mr-3" nz-icon nzType="info-circle" nzTheme="outline"></i>
                          <a purpleEditableText tranKey='my_reservations_informations' (click)="showInstructionsModal()"
                            class="fsp-14-5">{{ 'my_reservations_informations' | translation: 'Informazioni utili':
                            []}}</a>
                        </div>
                      </div>
                      <!-- <p class="ps-mb-0 my-reservations-item-ticket-box-disclaimer">{{ 'my_reservations_disclaimer' | translation: "L'accesso è consentito solo nelle fascie orarie prenotate": []}}</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="my-reservations-tab-mobile ps-w-100 ps-d-flex ps-d-lg-none ps-flex-wrap">
            <ng-container *ngIf="pastBookings.length > 0; else noNextBookingMobile">
              <div *ngFor="let book of pastBookings" class="my-reservations-item-box-container ps-w-100 ps-d-flex">
                <div class="my-reservations-item-info-box-col ps-w-100">
                  <div class="my-reservations-item-info-box past-booked ps-d-flex ps-flex-wrap ps-w-100">
                    <div class="ps-w-100 ps-d-flex ps-align-items-center ps-mb-3">
                      <img contentId="{{book.saleEventId}}-logo" contentTypeId="sale-event-logo" purpleEditableImage
                        class="my-reservations-item-info-box-sale-event-img" />
                      <div class="ps-d-flex ps-flex-wrap ps-w-100">
                        <h1 class="my-reservations-item-info-box-sale-event-name ps-m-0">{{book.saleEventName}}
                        </h1>
                        <h3 class="my-reservations-item-info-box-sale-event-address ps-d-flex ps-align-items-center">
                          <i nz-icon nzType="home" nzTheme="outline"></i>
                          {{book.address}}
                        </h3>
                      </div>
                    </div>

                    <nz-tabset>
                      <nz-tab [nzTitle]="mobileTemplateBook">
                        <ng-template #mobileTemplateBook purpleEditableText tranKey='my_reservations_slots_tab'>
                          <i nz-icon nzType="environment" nzTheme="outline"></i>
                          {{ 'my_reservations_slots_tab' | translation: 'Prenotazioni': []}}
                        </ng-template>
                        <div *ngFor="let slot of book.slots"
                          class="my-reservations-item-info-box-sale-event-slot ps-d-flex ps-align-items-center ps-justify-content-between ps-w-100">
                          <p class="ps-m-0 date"><span>{{slot.slotStartDate | saleEventDate:"EEE" }}
                            </span>
                            <br>
                            {{slot.slotStartDate | saleEventDate:"dd MMM"}}
                          </p>
                          <p class="ps-m-0 hours">{{slot.slotStartDate | saleEventDate:"shortTime" }}
                            <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                            {{slot.slotEndDate | saleEventDate:"shortTime" }}
                          </p>
                          <i nz-popover nzPopoverPlacement="right" [nzPopoverContent]="slot.roleName" class="star"
                            nz-icon nzType="tag" nzTheme="twotone" [nzTwotoneColor]="'#EBBF3E'"></i>
                        </div>
                      </nz-tab>
                      <nz-tab [nzTitle]="mobileTemplateTicket">
                        <ng-template #mobileTemplateTicket purpleEditableText tranKey='my_reservations_tickets_tab'>
                          <i nz-icon nzType="barcode" nzTheme="outline"></i>
                          {{ 'my_reservations_tickets_tab' | translation: 'Biglietti': []}}
                        </ng-template>
                        <div
                          class="ticket-box ps-d-flex ps-justify-content-start ps-flex-wrap ps-align-items-center ps-w-100">
                          <div class="ps-w-100 ps-d-flex ps-align-items-center">
                            <i class="fsp-18-0 ps-mr-3" nz-icon nzType="printer" nzTheme="outline"></i>
                            <a purpleEditableText tranKey='my_reservations_show_tickets' [href]="pdfSrcPast(book)"
                              class="fsp-14-5">{{ 'my_reservations_show_tickets' | translation: 'Visualizza biglietto':
                              []}}</a>
                          </div>
                          <div *ngIf="showCheckInInfo" class="ps-w-100 ps-d-flex ps-align-items-center ps-pt-3">
                            <i class="fsp-18-0 ps-mr-3" nz-icon nzType="info-circle" nzTheme="outline"></i>
                            <a purpleEditableText tranKey='my_reservations_informations'
                              (click)="showInstructionsDrawer()" class="fsp-14-5">{{ 'my_reservations_informations' |
                              translation: 'Informazioni utili': []}}</a>
                          </div>
                        </div>
                        <!-- <p class="ps-mb-0 my-reservations-item-ticket-box-disclaimer">{{ 'my_reservations_disclaimer' | translation: "L'accesso è consentito solo nelle fascie orarie prenotate": []}}</p> -->
                      </nz-tab>
                    </nz-tabset>






                  </div>
                </div>

              </div>
            </ng-container>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>


<ng-template #noNextBooking>
  <div class="ps-w-100 ps-d-flex ps-justify-content-center no-booking-box-container">

    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center no-booking-box">
      <div class="ps-w-100 ps-d-flex ps-justify-content-center animation-container">

        <ng-lottie [options]="options" width="300px" height="auto"></ng-lottie>
      </div>
      <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-mb-2">
        <h1 purpleEditableText tranKey='my_reservations_no_next_book_title' class="error-title ps-w-100">{{
          'my_reservations_no_next_book_title' | translation: 'Nessuna prenotazione trovata!': []}}</h1>
        <h3 purpleEditableText tranKey='my_reservations_no_next_book_subtile' class="error-subtitle ps-w-100">{{
          'my_reservations_no_next_book_subtile' | translation: 'Tante private sale ti aspettano':
          []}}</h3>
        <div purpleEditableText tranKey='my_reservations_no_next_book_subtile_link' class="error-back-home ps-w-100">{{
          'my_reservations_no_next_book_subtile_link' | translation: 'Vieni a':
          []}} <a purpleEditableText tranKey='my_reservations_no_next_book_discover_now'
            [routerLink]="['/', tsvc.currentLanguage.value, 'login']">{{ 'my_reservations_no_next_book_discover_now' |
            translation: 'scoprirle ora':
            []}}</a></div>
      </div>


    </div>
  </div>
</ng-template>

<ng-template #noNextBookingMobile>

  <div class="ps-w-100 ps-d-flex ps-justify-content-center no-booking-box-container">

    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center no-booking-box">
      <div class="ps-w-100 ps-d-flex ps-justify-content-center animation-container">

        <ng-lottie [options]="options" width="210px" height="auto"></ng-lottie>
      </div>
      <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-mb-2">
        <h1 purpleEditableText tranKey='my_reservations_no_next_book_title' class="error-title ps-w-100">{{
          'my_reservations_no_next_book_title' | translation: 'Nessuna prenotazione trovata!': []}}</h1>
        <h3 purpleEditableText tranKey='my_reservations_no_next_book_subtile' class="error-subtitle ps-w-100">{{
          'my_reservations_no_next_book_subtile' | translation: 'Tante private sale ti aspettano':
          []}}</h3>
        <div purpleEditableText tranKey='my_reservations_no_next_book_subtile_link' class="error-back-home ps-w-100">{{
          'my_reservations_no_next_book_subtile_link' | translation: 'Vieni a':
          []}}<a purpleEditableText tranKey='my_reservations_no_next_book_discover_now'
            [routerLink]="['/', tsvc.currentLanguage.value, 'login']">{{ 'my_reservations_no_next_book_discover_now' |
            translation: 'scoprirle ora':
            []}}</a></div>
      </div>


    </div>
  </div>
</ng-template>